<template>
  <div
    class="page-box"
  >
    <div class="top-box box">
      <div class="user-box box">
        <div class="img-box">
          <van-image :src="user.img"></van-image>
        </div>
        <div class="text-box">
          <div class="phone ellipsis">
            {{ user.nickName || '--' }}
          </div>
          <div
            v-show="user.bindCard"
            class="real-name"
          >
            已实名
          </div>
        </div>

        <div class="right-box box">
          <div class="text-a">
            {{ parseFloat(user.surplusAmount || 0).toFixed(2) }}
          </div>
          <div class="text-b">
            剩余额度(元)
          </div>
        </div>
      </div>
      <!-- 额度 -->
      <!-- <div class="quota-box">
        <div class="item-box item-box0">
          <div class="text1">
            9000.00
          </div>
          <div class="text2">
            信用额度
          </div>
        </div>
        <div class="border-y hei74"></div>
        <div class="item-box">
          <div class="text1">
            4000.00
          </div>
          <div class="text2">
            剩余额度
          </div>
        </div>
      </div> -->
    </div>

    <!-- info -->
    <div class="info-box box flex-b">
      <div
        class="item-box"
        @click="$router.push('/user/repay')"
      >
        <div class="img-box">
          <img src="@/assets/img/user/icon-a.png" />
        </div>
        <div class="text">
          我的账单
        </div>
      </div>
      <div
        class="item-box"
        @click="$router.push('/user/info')"
      >
        <div class="img-box">
          <img src="@/assets/img/user/icon-b.png" />
        </div>
        <div class="text">
          个人信息
        </div>
      </div>

      <div
        class="item-box"
        @click="$router.push('/user/bankInfo')"
      >
        <div class="img-box">
          <img src="@/assets/img/user/icon-c.png" />
        </div>
        <div class="text">
          银行卡
        </div>
      </div>

      <div
        class="item-box"
        @click="$router.push('/user/student')"
      >
        <div class="img-box">
          <img src="@/assets/img/user/icon-d.png" />
        </div>
        <div class="text">
          学员信息
        </div>
      </div>
    </div>

    <!-- list -->
    <div class="list-box-a list-box box">
      <div class="list-item">
        <div class="title-item">
          <img
            src="@/assets/img/user/icon-e.png"
            alt=""
          >
          我的订单
        </div>
        <van-divider />
        <div class="child-list">
          <div
            class="child-item"
            @click="$router.push('/order/fullOrder')"
          >
            <img
              src="@/assets/img/user/icon-f.png"
              alt=""
            >
            全款订单
          </div>
          <van-divider />
          <div
            class="child-item"
            @click="goOrderList"
          >
            <img
              src="@/assets/img/user/icon-g.png"
              alt=""
            >
            分期订单
          </div>
        </div>
      </div>
    </div>

    <div class="list-box-b list-box box">
      <div
        class="list-item"
        @click="$router.push('/org/order')"
      >
        <div class="title-item">
          <img
            src="@/assets/img/user/icon-h.png"
            alt=""
          >
          机构分期订单
        </div>
      </div>
      <van-divider />
      <div
        class="list-item"
        @click="$router.push('/org/period')"
      >
        <div class="title-item">
          <img
            src="@/assets/img/user/icon-i.png"
            alt=""
          >
          添加机构分期
        </div>
      </div>
      <div
        class="list-item"
        @click="$router.push('/org/settled')"
      >
        <div class="title-item">
          <img
            src="@/assets/img/user/icon-i.png"
            alt=""
          >
          机构入驻
        </div>
      </div>
      <van-divider />
      <div
        class="list-item"
        @click="$router.push('/user/feedback')"
      >
        <div class="title-item">
          <img
            src="@/assets/img/user/icon-j.png"
            alt=""
          >
          意见反馈
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UserIndex',
  components: {
  },
  data () {
    return {
      isGlobalPage: false,
      activeNames: ['1'],
      user: {}
    }
  },
  computed: {
  },
  created () {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo () {
      let data = {}
      let res = await this.$http.userApi.userDetail(data)
      console.log(res, 'user')
      if (res.code === 200) {
        this.user = res.msg || {}
      }
    },
    tipFun() {
      this.$toast('暂未开通，敬请期待')
    },
    goOrderList () {
      this.$router.push('/order/index')
    }
  }
}
</script>

<style lang="scss" scoped>
.page-box {
  background: #f5f5f5;
  min-height: 100%;
  .top-box {
    height: 166px;
    background: url('~@/assets/img/page-bg.png') center center no-repeat;
    background-size: 100% 192px;
    padding-top: 88px;
    padding-left: 15px;
    position: relative;
    .user-box {
      padding: 20px 10px;
      .img-box {
        width: 45px;
        height: 45px;
        float: left;
        border-radius: 50%;
        overflow: hidden;
        /deep/ .van-image {
          height: 100%;
          width: 100%;
        }
      }
      .text-box {
        float: left;
        margin-left: 10px;
        padding-top: 6px;
        width: 260px;
        .phone {
          width: 120px;
          line-height: 12px;
          font-size: 13px;
        }
        .real-name {
          width: 42px;
          margin-top: 10px;
          line-height: 16px;
          border-radius: 8px;
          background: #51B464;
          font-size: 10px;
          color: #fff;
          text-align: center;
        }
      }
    }
    .quota-box {
      margin-top: 25px;
      margin-bottom: 10px;
      display: flex;
      position: relative;
      .item-box {
        flex: 1;
        padding: 0 18px;
        .text1 {
          line-height: 29px;
          font-size: 26px;
          font-weight:500;
        }
        .text2 {
          font-size: 13px;
          color: #7c7c7c;
          line-height: 22px;
        }
      }
      .item-box0 {
        text-align: right;
      }
      .border-y {
        height: 38px;
        width: 1px;
        position: absolute;
        left: 50%;
        top: 50%;
        margin: -19px 0 0 -0.5px;
        background: #7c7c7c;
      }
    }
    .right-box {
      width: 220px;
      height: 46px;
      position: absolute;
      right: 10px;
      top: 20px;
      text-align: right;
      .text-a {
        font-size: 26px;
      }
      .text-b {
        font-size: 13px;
        color: #7C7C7C;
      }
    }
  }
  .info-box {
    height: 96px;
    background: #fff;
    display: flex;
    padding: 0 15px;
    text-align: center;
    .item-box {
      flex: 1;
      .img-box {
        width: 26px;
        height: 26px;
        display: inline-block;
        margin-bottom: 6px;
        img {
          width: 100%;height: 100%;
        }
      }
      .text {
        font-size: 12px;
        line-height: 18px;
      }
    }
  }
  .list-box {
    background: #fff;
    line-height: 50px;
    font-size: 14px;
    /deep/.van-divider--hairline {
      margin: 0;
    }
  }
  .list-box-a {
    margin-top: 10px;
    .child-list{
      padding-left: 40px;
      padding-right: 15px;
      .child-item {
        position: relative;
        padding-left: 40px;
        img {
          position: absolute;
          left: 15px;
          top: 16px;
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  .list-box {
    .title-item {
      padding-left: 40px;
      position: relative;
      img {
        position: absolute;
        left: 15px;
        top: 16px;
        width: 18px;
        height: 18px;
      }
    }
  }
  .list-box-b {
    margin-top:10px;
  }
}
</style>
